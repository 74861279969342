<template>
    <div class="container">
        <figure class="highcharts-figure">
            <div :id="nameInLowerCase">
            </div>
        </figure>
    </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
    name: 'PieChart',
    props: [
        'title',
        'data',
        'name',
        'firstLevel',
        'secondLevel'
    ],
    emits: [
        'reloadCharts'
    ],
    mounted() {
        this.data.forEach(el => {
            el.events = {
                click: () => {
                    let levels = this.firstLevel + (this.secondLevel ? '-' + this.secondLevel : '');
                    let filter = {
                        levels: levels,
                        value: el.name
                    };
                    this.$store.dispatch('filterPeopleOnClick', filter);
                    this.$emit('reloadCharts');
                }
            }
        });
        Highcharts.chart(
            this.nameInLowerCase,
            {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: this.title
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: this.name,
                    colorByPoint: true,
                    data: this.data
                }]
            }
        );
    },
    computed: {
        nameInLowerCase() {
            return 'pie-chart-' + this.name.toLowerCase();
        }
    }
}
</script>
