<template>
    <div class="container">
        <h1>Camemberts</h1>

        <div class="row">
            <pie-chart
                v-for="chart in charts"
                :key="chart.name"
                :title="chart.title"
                :data="chart.data"
                :name="chart.name"
                :first-level="chart.firstLevel"
                :second-level="chart.secondLevel"
                v-on:reloadCharts="reloadCharts"
            />
        </div>
    </div>
</template>

<script>
import PieChart from '../components/charts/PieChart';

export default {
    name: 'PieChartGlobal',
    components: {
        PieChart
    },
    emits: [
        'reloadView'
    ],
    data() {
        return {
            charts: [
                {
                    title: 'Couleurs favorites',
                    name: 'Couleur',
                    firstLevel: 'preferences',
                    secondLevel: 'favorite_color',
                    data: null
                },
                {
                    title: 'Animaux favoris',
                    name: 'Animal',
                    firstLevel: 'preferences',
                    secondLevel: 'favorite_pet',
                    data: null
                },
                {
                    title: 'Fruits favoris',
                    name: 'Fruit',
                    firstLevel: 'preferences',
                    secondLevel: 'favorite_fruit',
                    data: null
                },
                {
                    title: 'Sexes',
                    name: 'Sexe',
                    firstLevel: 'gender',
                    secondLevel: null,
                    data: null
                },
                {
                    title: 'Pays',
                    name: 'Pays',
                    firstLevel: 'contact',
                    secondLevel: 'country',
                    data: null
                }
            ]
        }
    },
    created() {
        // Get data for each pie chart
        this.charts.forEach(chart => {
            chart.data = this.$store.getters.getDataForChart(chart.firstLevel, chart.secondLevel);
        });
    },
    methods: {
        reloadCharts() {
            this.$emit('reloadView');
        }
    }
}
</script>
